<template>
  <div class="filter-guests">
    <button
      ref="buttonEl"
      type="button"
      class="search-toggle"
      @click="toggle()"
    >
      {{ labelText }}
      <SearchGuestsText
        :guests="guests"
        :pet-friendly="petFriendly"
      />
    </button>

    <ClientOnly>
      <div
        v-if="isMediumBreakpoint && showForm"
        ref="floatingEl"
        class="floating-box"
      >
        <LazySearchGuestsForm
          :guests="guestsModel"
          :pet-friendly="petFriendlyModel"
          @update:guests="($event) => updateGuests($event, !showFormButtons)"
          @update:pet-friendly="($event) => updatePetFriendly($event, !showFormButtons)"
        >
          <template
            v-if="showFormButtons"
            #default="{ guests: newGuests, petFriendly: newPetFriendly }"
          >
            <LazySearchFormButtons
              @apply="apply({ guests: newGuests, petFriendly: newPetFriendly })"
              @clear="clear()"
            />
          </template>
        </LazySearchGuestsForm>
      </div>

      <OverlayDrawer
        v-if="!isMediumBreakpoint"
        :visible="showForm"
        :prevent-control-scroll="preventOverlayControlScroll"
        @close="close()"
      >
        <template #header>
          <strong>{{ labelText }}</strong>
        </template>

        <LazySearchGuestsForm
          :guests="guestsModel"
          :pet-friendly="petFriendlyModel"
          @update:guests="($event) => updateGuests($event, false)"
          @update:pet-friendly="($event) => updatePetFriendly($event, false)"
        >
          <template #default="{ guests: newGuests, petFriendly: newPetFriendly }">
            <LazySearchFormButtons
              @apply="apply({ guests: newGuests, petFriendly: newPetFriendly })"
              @clear="clear()"
            />
          </template>
        </LazySearchGuestsForm>
      </OverlayDrawer>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const props = withDefaults(defineProps<{
  guests?: { adults?: number, children?: number }
  petFriendly: boolean
  label?: string
  openGuestPicker?: boolean
  showFormButtons?: boolean
  preventOverlayControlScroll?: boolean
}>(), {
  petFriendly: false,
  label: '',
  openGuestPicker: false,
  showFormButtons: false,
  preventOverlayControlScroll: false,
})

const emit = defineEmits(['update', 'apply', 'clear'])
const { isMediumBreakpoint } = useBreakpoint()

const showForm = ref(false)
const forceOpen = ref(false)
const guestsModel = ref(props.guests)
const petFriendlyModel = ref(props.petFriendly)

const labelText = computed(() => props.label || t('guests.title'))

const { $search } = useNuxtApp()

watch(
  () => props.openGuestPicker,
  (newVal) => {
    if (newVal) {
      forceOpen.value = true
      toggle()
      setTimeout(() => {
        forceOpen.value = false
      }, 250)
    }
  },
)

watch(
  () => $search.parameters.filters.petFriendly,
  (newVal) => {
    petFriendlyModel.value = Boolean(newVal)
  },
)

function toggle() {
  showForm.value = !showForm.value
}

function close() {
  showForm.value = false
}

function updateGuests(guests: { adults?: number, children?: number }, emitUpdate = false) {
  guestsModel.value = guests

  if (!emitUpdate) {
    return
  }

  emit('update', { guests, petFriendly: petFriendlyModel.value })
}

function updatePetFriendly(petFriendly: boolean, emitUpdate = false) {
  petFriendlyModel.value = petFriendly

  if (!emitUpdate) {
    return
  }

  emit('update', { guests: guestsModel.value, petFriendly })
}

function apply({ guests, petFriendly }: { guests: { adults?: number, children?: number }, petFriendly: boolean }) {
  emit('apply', { guests, petFriendly })
  close()
}

function clear() {
  emit('clear')
  close()
}

/**
 * Clicking outside
 */
const floatingEl = ref(null)
const buttonEl = ref(null)
onClickOutside(
  floatingEl,
  () => {
    if (forceOpen.value) {
      return
    }

    toggle()
  },
  { ignore: [buttonEl] },
)
</script>

<i18n src="~/locales/common/search/form/guests.json" lang="json" />
