<template>
  <div class="filter-dates">
    <button
      ref="buttonEl"
      type="button"
      class="search-toggle"
      @click="toggle()"
    >
      {{ t('dates.title') }}
      <SearchDatesText :dates="dates" />
    </button>

    <div
      v-show="isMediumBreakpoint && showForm"
      ref="floatingEl"
      class="floating-box"
    >
      <div
        v-if="popperInstance"
        ref="popperEl"
      >
        <LazySearchDatesForm
          :dates="datesModel"
          @update:dates="($event) => updateDates($event, !showFormButtons)"
          @loaded="updatePopper()"
        >
          <template
            v-if="showFormButtons"
            #default="{ dates: newDates }"
          >
            <BannersMidWeek v-if="shouldShowMidweekBanner" />
            <LazySearchFormButtons
              @apply="apply({ dates: newDates })"
              @clear="clear()"
            />
          </template>
          <template v-if="!showFormButtons">
            <BannersMidWeek v-if="shouldShowMidweekBanner" />
          </template>
        </LazySearchDatesForm>
      </div>
    </div>

    <ClientOnly>
      <OverlayDrawer
        v-if="!isMediumBreakpoint"
        :visible="showForm"
        :prevent-control-scroll="preventOverlayControlScroll"
        @close="close()"
      >
        <LazySearchDatesForm
          :dates="datesModel"
          @update:dates="($event) => updateDates($event, false)"
        >
          <template #default="{ dates: newDates }">
            <BannersMidWeek v-if="shouldShowMidweekBanner" />
            <LazySearchFormButtons
              @apply="apply({ dates: newDates })"
              @clear="clear()"
            />
          </template>
        </LazySearchDatesForm>
      </OverlayDrawer>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { createPopper } from '@popperjs/core'

const { isMediumBreakpoint } = useBreakpoint()
const { t } = useI18n({
  useScope: 'local',
})

const props = defineProps({
  dates: {
    type: Object,
    default: null,
  },

  openCalendar: {
    type: Boolean,
    default: false,
  },

  showFormButtons: {
    type: Boolean,
    default: false,
  },

  preventOverlayControlScroll: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['apply', 'clear', 'close', 'update:dates'])

const { showForm, forceOpen, toggle, close } = useShowForm()
const { buttonEl, floatingEl, popperEl, popperInstance, updatePopper } = useCalendarPopper()

const { $experiment } = useNuxtApp()
const shouldShowMidweekBanner = computed(() => {
  return $experiment.isEnabled('rvz19720MidweekCalendarExperiment')
})

const datesModel = ref(props.dates)
watch(
  () => props.dates,
  (newVal) => {
    datesModel.value = newVal
  },
  { deep: true },
)

function updateDates(dates: Record<string, unknown>, emitUpdate = false) {
  datesModel.value = dates

  if (!emitUpdate) {
    return
  }

  emit('update:dates', dates)

  if (dates.start && dates.end) {
    close()
  }
}

function apply({ dates }: { dates: Record<string, unknown> }) {
  emit('apply', { dates })
  close()
}

function clear() {
  emit('clear')
  close()
}

function useShowForm() {
  const showForm = ref(false)
  const forceOpen = ref(false)

  watch(
    () => props.openCalendar,
    (newVal) => {
      if (newVal) {
        forceOpen.value = true
        toggle()
        setTimeout(() => {
          forceOpen.value = false
        }, 250)
      }
    },
  )

  function toggle() {
    showForm.value = !showForm.value

    if (!showForm.value) {
      emit('close')
    }
  }

  function close() {
    showForm.value = false
    emit('close')
  }

  return {
    showForm,
    forceOpen,
    toggle,
    close,
  }
}

function useCalendarPopper() {
  const buttonEl = ref<HTMLButtonElement | null>(null)
  const floatingEl = ref<HTMLDivElement | null>(null)
  const popperEl = ref(null)
  const popperInstance = ref<ReturnType<typeof createPopper> | null>(null)

  function initPopper() {
    if (popperInstance.value || !buttonEl.value || !floatingEl.value) {
      return
    }

    popperInstance.value = createPopper(buttonEl.value, floatingEl.value, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: () => {
              return [0, 8]
            },
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
          },
        },
      ],
    })
  }

  function updatePopper() {
    popperInstance.value?.update()
  }

  watch(showForm, () => {
    if (isMediumBreakpoint.value && showForm.value) {
      if (popperInstance.value) {
        updatePopper()
      }
      else {
        initPopper()
      }
    }
  })

  onClickOutside(
    popperEl,
    () => {
      if (!isMediumBreakpoint.value || !showForm.value || forceOpen.value) {
        return
      }
      toggle()
    },
    { ignore: [buttonEl] },
  )

  onBeforeUnmount(() => {
    popperInstance.value?.destroy()
  })

  return {
    buttonEl,
    floatingEl,
    popperEl,
    popperInstance,
    updatePopper,
  }
}
</script>

<style lang="scss" scoped>
:deep(.calendars-container) {
  @include media-min-size(medium) {
    max-width: none;
  }
}
:deep(.floating-box) {
  max-width: max-content;
}
</style>

<i18n src="~/locales/common/search/form/dates.json" lang="json" />
