<template>
  <div class="filter-location">
    <button
      v-show="!showField"
      type="button"
      class="search-toggle"
      @click="() => toggle()"
    >
      {{ labelText ?? t('location.title') }}
      <SearchLocationText :location="location" />
    </button>

    <LazySearchLocationForm
      v-show="showField"
      :location="locationModel"
      :label="labelText"
      :is-visbible="showField"
      show-label
      @blur="close()"
      @update="($event) => updateLocation($event)"
      @clear="clearLocation"
    />

    <ClientOnly>
      <OverlayDrawer
        v-if="!isMediumBreakpoint"
        :visible="showForm"
        :title="labelText ?? t('location.title')"
        position="top"
        :prevent-control-scroll="preventOverlayControlScroll"
        @close="close()"
      >
        <LazySearchLocationForm
          :location="locationModel"
          :label="labelText"
          :is-visbible="showForm"
          @update="($event) => updateLocation($event)"
          @clear="clearLocation"
        />
      </OverlayDrawer>
    </ClientOnly>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      default: null,
    },

    labelText: {
      type: String,
      default: null,
    },

    withSearchButton: {
      type: Boolean,
      default: false,
    },

    preventOverlayControlScroll: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update', 'clear', 'apply'],

  setup() {
    const { t } = useI18n({
      useScope: 'local',
    })

    const { isMediumBreakpoint } = useBreakpoint()

    return {
      isMediumBreakpoint,
      t,
    }
  },

  data() {
    return {
      showForm: false,
      locationModel: this.location,
    }
  },

  computed: {
    showField() {
      return this.isMediumBreakpoint && this.showForm
    },
  },

  watch: {
    location: function (newVal) {
      this.locationModel = newVal
    },
  },

  methods: {
    toggle() {
      this.showForm = !this.showForm
    },

    close() {
      this.showForm = false
    },

    updateLocation(location) {
      if (this.withSearchButton) {
        this.$emit('update', location)
      }
      else {
        this.$emit('apply', location)
      }

      if (location) {
        this.close()
      }
    },

    clearLocation() {
      this.$emit('clear')
    },
  },
}
</script>

<i18n src="~/locales/common/search/form/location.json" lang="json" />

<i18n src="~/locales/common/location.json" lang="json" />
